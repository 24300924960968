import { Navigate, Routes, Route } from "react-router-dom";
import { useHeader } from "@Light/components/page/header/context";
import { PaymentMethod } from "./PaymentMethod";
import { UpdatePaymentMethod } from "./UpdatePaymentMethod";
import { PaymentMethodUpdated } from "./PaymentMethodUpdated";

export const PAYMENT_METHOD_LINK = "/accounts/payment-method/";

export const PaymentMethodRoute: React.FC = () => {
  const { setDivider } = useHeader();
  setDivider(false);
  return (
    <Routes>
      <Route path="/" element={<PaymentMethod />} />
      <Route path="/update" element={<UpdatePaymentMethod />} />
      <Route
        path="/payment-method-updated"
        element={<PaymentMethodUpdated />}
      />
      <Route path="/*" element={<Navigate to={PAYMENT_METHOD_LINK} />} />
    </Routes>
  );
};
