import { LinkList } from "@Light/components/account/Account/LinkList";
import { LinkItem } from "@Light/components/account/Account/LinkItem";
import { useScaffold } from "@Light/scaffold";
import { useHeader } from "@Light/components/page/header/context";
import { useCallback } from "react";
import {
  QuestionMarkCircleIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "@Light/utils/context";

export const Support: React.FC = () => {
  const scaffold = useScaffold();
  const navigate = useNavigate();
  const { setBack, setTitle, setDivider } = useHeader();
  setBack(useCallback(() => navigate("/"), [navigate]));
  setTitle("Help & support");
  setDivider(false);
  const PageBody = scaffold.page.pageBody;
  const Link = scaffold.system.link;
  return (
    <PageBody>
      <div className="pt-8">
        <LinkList>
          <Link to="/support/faq">
            <LinkItem
              leftIcon={<QuestionMarkCircleIcon className="w-5 h-5" />}
              text="FAQ"
            />
          </Link>
          <Link to="/support/contact">
            <LinkItem
              leftIcon={<EnvelopeIcon className="w-5 h-5" />}
              text="Contact us"
            />
          </Link>
        </LinkList>
      </div>
    </PageBody>
  );
};
