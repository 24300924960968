import { ErrorBox } from "@Light/components/page/errors/ErrorBox";
import { useScaffold } from "@Light/scaffold";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

export type ServiceCancelledNotificationProps = {};

export const ServiceCancelledNotification: React.FC<
  ServiceCancelledNotificationProps
> = ({}) => {
  const scaffold = useScaffold();
  const Link = scaffold.system.link;
  return (
    <ErrorBox
      title="Your service has been cancelled"
      subtitle="Is there anything we can do to change your mind?"
    >
      <Link to="/support/contact">
        <div
          className={clsx(
            "flex flex-row items-center gap-1 font-medium text-sm",
          )}
        >
          <div>Re-enroll with us</div>
          <ArrowRightIcon className="w-4 h-4" />
        </div>
      </Link>
    </ErrorBox>
  );
};
