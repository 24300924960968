import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useScaffold } from "@Light/scaffold";
import { useAPI } from "@/services/api";
import { PageBody } from "@Light/components/page/PageBody";
import clsx from "clsx";
import { hasFeatureFlag } from "../WithConfiguration";

export function Login() {
  const { useLoginEmailTokenMutation } = useAPI();
  const [loginEmailToken, loginEmailTokenState] = useLoginEmailTokenMutation();
  const allowSignups = hasFeatureFlag("CUSTOMER_CLIENT_ALLOW_SIGNUPS");

  const scaffold = useScaffold();
  const Field = scaffold.system.field;
  const Label = scaffold.system.label;
  const Input = scaffold.system.input;
  const ErrorMessage = scaffold.system.errorMessage;
  const Button = scaffold.system.button;
  const { register, handleSubmit, watch } = useForm();
  const { email } = watch();

  const onSubmit = useCallback(
    ({ email }: any) => {
      loginEmailToken({ email });
    },
    [loginEmailToken],
  );

  const Navigate = scaffold.system.navigate;
  const Link = scaffold.system.link;
  if (loginEmailTokenState.isSuccess) {
    const params = new URLSearchParams({ email });
    return <Navigate to={`/login/check-email?${params}`} />;
  }

  return (
    <PageBody
      title={`Welcome to ${scaffold.page.companyName}`}
      subtitle="Sign in with your email."
    >
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col items-center gap-4 w-full">
          <Field className="w-full">
            <Label>Email</Label>
            <Input
              type="email"
              {...register("email", { required: "required" })}
            />
            {loginEmailTokenState.isError ? (
              <ErrorMessage>
                We encountered an error while trying to log you in. Please try
                again later.
              </ErrorMessage>
            ) : null}
          </Field>
          <div className="flex flex-col gap-2 w-full">
            <Button
              type="submit"
              className="w-full"
              disabled={loginEmailTokenState.isLoading}
            >
              Continue
            </Button>
            {allowSignups && (
              <div
                className={clsx("text-sm", scaffold.page.colors.text.tertiary)}
              >
                <span>If you haven't yet enrolled in a plan, click</span>{" "}
                <span
                  className={clsx(
                    "font-medium",
                    scaffold.page.colors.text.primary,
                  )}
                >
                  <Link to="/signup">here</Link>
                </span>{" "}
                <span>to sign up.</span>
              </div>
            )}
          </div>
        </div>
      </form>
    </PageBody>
  );
}
