import { Navigate, Route, Routes } from "react-router-dom";
import { useAccount, useLocation } from "../page/account";
import { EnergyConsumption } from "./EnergyConsumption";
import { WaitingForService } from "./WaitingForService";

export type UsageRouteProps = {};

export const UsageRoute: React.FC<UsageRouteProps> = ({}) => {
  const account = useAccount();
  const location = useLocation();
  if (!account.enrollment.is_service_active && !location.final_service_date) {
    return (
      <Routes>
        <Route path="" element={<WaitingForService />} />
        <Route path="*" element={<Navigate to="/dash/usage/" />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="*" element={<EnergyConsumption />} />
    </Routes>
  );
};
