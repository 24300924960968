import { useHeader } from "@Light/components/page/header/context";
import { useScaffold } from "@Light/scaffold";
import React from "react";
import { PaymentMethodCard } from "./PaymentMethodCard";
import { QueryLoader } from "@Light/components/app/QueryLoader";
import { useLight } from "@Light/services/light";
import { ErrorBox } from "@Light/components/page/errors/ErrorBox";
import { UpdatePaymentMethodLink } from "./UpdatePaymentMethodLink";

export type PaymentMethodProps = {};

export const PaymentMethod: React.FC<PaymentMethodProps> = ({}) => {
  const scaffold = useScaffold();
  const { setTitle, setBackNavigate } = useHeader();
  const { useGetPaymentMethodQuery } = useLight();
  const paymentMethod = useGetPaymentMethodQuery();

  setTitle("Payment method");
  setBackNavigate("/account/");

  const PageBody = scaffold.page.pageBody;
  const Divided = scaffold.page.divided;
  const DetailLabel = scaffold.page.detail.detailLabel;
  return (
    <PageBody>
      <Divided className="pt-8">
        <div className="flex flex-col gap-2">
          <DetailLabel>Automatic payments</DetailLabel>
          <div>
            Your invoice will be available at the end of each billing cycle, and
            your credit card will be charged 16 days later
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <QueryLoader queries={[paymentMethod]} allowError>
            {paymentMethod.isSuccess ? (
              <>
                <DetailLabel>Card details</DetailLabel>
                <PaymentMethodCard />
              </>
            ) : (
              <ErrorBox
                title="No payment method found"
                subtitle={
                  <div className="flex flex-col gap-2">
                    <div>
                      We have no means to charge you for your electricity usage.
                    </div>
                    <div>
                      Please click the "Add payment method" link below to add a
                      payment method to your account.
                    </div>
                  </div>
                }
              />
            )}
            <UpdatePaymentMethodLink />
          </QueryLoader>
        </div>
      </Divided>
    </PageBody>
  );
};
